export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/404": [3],
		"/about-us": [~4],
		"/bantuan": [~5],
		"/campaigns": [~6],
		"/campaigns/[slug]": [~7],
		"/campaigns/[slug]/donate": [~8],
		"/events": [~9],
		"/events/categories/[slug]": [~15],
		"/events/[slug]": [~10],
		"/events/[slug]/form": [~11],
		"/events/[slug]/old__": [~12],
		"/events/[slug]/rsvp/checkout": [~13],
		"/events/[slug]/summary": [~14],
		"/faq": [~16],
		"/forgot-password": [~17],
		"/login": [~18],
		"/members": [~19],
		"/members/[id]": [~20],
		"/mobile-campaigns/[slug]/donate": [~21],
		"/news": [~22],
		"/news/categories/[slug]": [~24],
		"/news/[slug]": [~23],
		"/payment/[number]": [~25],
		"/privacy-policy": [~26],
		"/profile": [~27],
		"/register": [~28],
		"/stores/product": [~29],
		"/stores/product/categories/[id]": [~32],
		"/stores/product/[id]": [~30],
		"/stores/product/[id]/checkout": [~31],
		"/subscribe": [~33],
		"/terms-conditions": [~34],
		"/transaction/[number]": [~35],
		"/vacancies": [~36],
		"/vacancies/[slug]": [~37]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';